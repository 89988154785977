/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import 'react-phone-number-input/style.css';
import { logEvent } from 'firebase/analytics';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { analytics } from 'firebaseConfig';
import { dispatchActionsOnFirstLoad } from 'Features/global/globalAction';
import {
  CHECK_CUSTOMER_EXISTS,
  CREATE_CUSTOMER_ACCOUNT_MUTATION,
  GENERATE_CUSTOMER_TOKEN_MOBILE,
  GET_VERSION,
  GET_ALL_STORE_CONFIG,
} from 'Gql/query';
import { changeFormType, changeTrigger } from 'Features/login';
import { registerSchema } from 'Utils/ValidationSchema/YupSchema';
import { notification } from 'Utils/Toast';
import loginLogo from 'assets/images/GBook_Logo-B2C.svg';
import { CaretDown, CheckCircle } from 'phosphor-react';
import generatePassword from 'Utils/generatePassword';

function Register({ formField, codes, currency }) {
  const [loading, setloading] = useState(false);
  const [rewardAmount, setRewardAmount] = useState();
  const [GenerateCustomerTokenMobile] = useMutation(GENERATE_CUSTOMER_TOKEN_MOBILE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get('referral');

  // get the all versions
  const { data } = useQuery(GET_VERSION);

  // check the customer exist in out database.
  const [CheckCustomerExists] = useLazyQuery(CHECK_CUSTOMER_EXISTS, {
    fetchPolicy: 'no-cache',
  });

  // Register the new user
  const [CreateCustomerAccountMutation] = useMutation(CREATE_CUSTOMER_ACCOUNT_MUTATION);

  // reward for the new user
  const [getStoreConfig] = useLazyQuery(GET_ALL_STORE_CONFIG, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const fetchStoreConfig = async () => {
      try {
        const { data } = await getStoreConfig();
        if (data?.storeConfig?.gb_reward) {
          setRewardAmount(data.storeConfig.gb_reward);
        }
      } catch (error) {
        console.error('Error fetching store config:', error);
      }
    };
    fetchStoreConfig();
  }, [getStoreConfig]);

  // Handle Form validation
  const formikProps = {
    initialValues: {
      firstname: '',
      lastname: '',
      phone: formField.type === 'phone' ? formField.field.replace('+91', '') : '',
      email: formField.type === 'email' ? formField.field : '',
      choice1: true,
      choice2: false,
    },
    validationSchema: registerSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setloading(true);
      try {
        createUserWithMegento(values?.phone);
      } catch (error) {
        setloading(false);
      }
    },
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched, setErrors, isValid } =
    useFormik(formikProps);

  const password = generatePassword();

  // Register the new user
  const createUserWithMegento = async (phone) => {
    try {
      await CheckCustomerExists({
        variables: {
          fieldValue: formField.type === 'email' ? values.email : `91${phone}`,
          type: formField.type === 'email' ? 'email' : 'mobile',
        },
      }).then(async (result) => {
        if (result.data.checkCustomerExists === false) {
          await CreateCustomerAccountMutation({
            variables: {
              input: {
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                password,
                is_subscribed: values.choice2,
                privacy_policy_version: data?.getVersion[0]?.privacy_policy_version,
                software_agreement_version: data?.getVersion[0]?.software_agreement_version,
                terms_condition_version: data?.getVersion[0]?.terms_condition_version,
              },
              mobileNumber: parseInt(`91${phone}`),
              otp: codes,
              type: formField.type === 'phone' ? 'mobile' : 'email',
              websiteId: 1,
              referralCode: referralCode || '',
            },
          }).then((createUser) => {
            if (createUser.data.hasOwnProperty('errors')) {
              setloading(false);
              throw new Error(createUser.data.errors[0].message);
            } else {
              setloading(false);
              window.dataLayer.push({
                event: 'REGISTERED',
                buttonName: 'Registered',
              });
              logEvent(analytics, 'REGISTERED');
              LoginUser(`91${phone}`, password);

              if (window.location.pathname.includes('/account-deleted')) {
                navigate('/');
              }
            }
          });
        } else if (formField.type === 'email') {
          setErrors({ email: 'Email ID is already registered' });
          setloading(() => false);
        } else {
          setErrors({ phone: 'Mobile number is already registered' });
          setloading(() => false);
        }
      });
    } catch (err) {
      if (
        err?.message ===
        'A customer with the same email address already exists in an associated website.'
      ) {
        setErrors({ email: 'Email ID is already registered' });
      } else {
        notification.error(err.message || 'Oops! Something went wrong, Please try again');
      }
      setloading(false);
    }
  };

  // User Login functionality
  const LoginUser = async (mobile, password) => {
    try {
      await GenerateCustomerTokenMobile({
        variables: { mobile, password },
      }).then((result) => {
        if (result.data.hasOwnProperty('errors')) {
          notification.error('Oops! Something went wrong, Please try again');
          throw new Error(result.data.errors[0].message);
        }
        localStorage.setItem('token', result.data.generateCustomerTokenMobile.token);
        dispatchEvent(new Event('storage'));
        dispatch(dispatchActionsOnFirstLoad());
        setloading(() => false);
        dispatch(changeFormType('login'));
        dispatch(changeTrigger());
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'UserRegistered',
          ecommerce: {
            method: 'Number',
          },
        });
        notification.success('Yay! You have registered successfully');
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    document.body.classList.add('login-modal-open');
    return () => {
      document.body.classList.remove('login-modal-open');
    };
  }, []);

  return (
    <div className='login registerFormWrapper' data-testid='register'>
      <div className='login__form'>
        <img src={loginLogo} alt='Glamourbook' />
        <p className='subText'>
          Beauty's new playground where everyone is a main character. Discover brands from around
          the world, all in one place.
        </p>
        <p className='register_amt'>
          Sign up now & get cash worth{' '}
          {new Intl.NumberFormat('en-us', {
            style: 'currency',
            currency: currency || 'INR',
            maximumFractionDigits: 0,
          })
            .format(rewardAmount)
            .replace(/^(\D+)/, '$1 ')}
          &nbsp;in your GlamourBook wallet!
        </p>

        <form className='registerForm registerFormOuter'>
          <div>
            <h5>Let's get started</h5>
            <p className='regIntro'>
              Creating an account is quick and simple & allows you to personalize your experience
            </p>
            <div className='input__holder d-flex flex-column registerForm'>
              <div>
                <div className='regFormGroup mt-0'>
                  <label htmlFor='firstname'>First Name*</label>
                  <input
                    type='text'
                    className={`input ${touched.firstname && errors.firstname ? 'errorShake' : ''}`}
                    autoComplete='off'
                    id='firstname'
                    name='firstname'
                    placeholder='Enter First Name'
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={50}
                    data-testid='username'
                  />
                </div>
                {touched.firstname && errors.firstname ? (
                  <p className='registerErrors' style={{ maxWidth: '280px' }}>
                    {errors.firstname}
                  </p>
                ) : null}
              </div>
              <div>
                <div className='regFormGroup'>
                  <label htmlFor='lastname'>Last Name*</label>
                  <input
                    type='text'
                    className={`input ${touched.lastname && errors.lastname ? 'errorShake' : ''}`}
                    id='lastname'
                    name='lastname'
                    placeholder='Enter Last Name'
                    autoComplete='off'
                    maxLength={30}
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid='username'
                  />
                </div>
                {touched.lastname && errors.lastname ? (
                  <p className='registerErrors' style={{ maxWidth: '180px' }}>
                    {errors.lastname}
                  </p>
                ) : null}
              </div>
              <div>
                <div className='regFormGroup'>
                  <label htmlFor='phone'>Mobile Number*</label>
                  <CaretDown size={12} weight='bold' color='#d9d9d9' className='downArrow' />
                  <div className='separator' />
                  <input
                    type='tel'
                    disabled={formField.type === 'phone'}
                    placeholder='1234567890'
                    className={`input ${touched.phone && errors.phone ? 'errorShake' : ''}`}
                    id='phone'
                    name='phone'
                    autoComplete='off'
                    value={`+91            ${values.phone}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={10}
                  />
                  {formField.type === 'phone' && (
                    <CheckCircle size={19} color='#71be4c' weight='fill' />
                  )}
                </div>
                {touched.phone && errors.phone ? (
                  <p className='registerErrors'>{errors.phone}</p>
                ) : null}
              </div>

              <div>
                <div className='regFormGroup'>
                  <label htmlFor='email'>Email ID*</label>
                  <input
                    type='email'
                    className={`input ${touched.email && errors.email ? 'errorShake' : ''}`}
                    name='email'
                    id='email'
                    placeholder='Enter Email ID'
                    value={formField.type === 'email' ? formField.field : values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid='email'
                    disabled={formField.type === 'email'}
                  />
                </div>
                {touched.email && errors.email ? (
                  <p className='registerErrors'>{errors.email}</p>
                ) : null}
              </div>

              <div className='d-flex flex-row mt-2'>
                <label className='login-checkbox-button d-flex justify-content-center align-items-center'>
                  <input
                    type='checkbox'
                    // className='login-checkbox-button__input'
                    className={`login-checkbox-button__input ${
                      touched.choice1 && errors.choice1 ? 'errorShake' : ''
                    }`}
                    id='choice1'
                    name='choice1'
                    checked={values.choice1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <span
                    className='login-checkbox-button__control boxCheck '
                    style={{ aspectRatio: '1/1' }}
                  />
                  <p className=' signup_fotter_text'>
                    I agree to GlamourBook's{' '}
                    <Link
                      onClick={() => {
                        dispatch(changeTrigger());
                      }}
                      to='/termsandconditions'
                    >
                      {' '}
                      <span className='fw-semibold text-decoration-underline'>
                        Terms & Conditions
                      </span>{' '}
                    </Link>
                    and{' '}
                    <Link
                      onClick={() => {
                        dispatch(changeTrigger());
                      }}
                      to='/privacypolicies'
                    >
                      <span className='fw-semibold text-decoration-underline'>Privacy Policy</span>
                    </Link>
                  </p>
                </label>
              </div>
              <div className='position-relative'>
                {touched.choice1 && errors.choice1 ? (
                  <p className='registerErrors'>{errors.choice1 || errors.choice2}</p>
                ) : null}
              </div>
            </div>
          </div>
          <input
            type='submit'
            onClick={handleSubmit}
            className='loginCta mx-auto whiteCta'
            id='submit'
            value={loading ? 'Loading...' : 'Register'}
            data-testid='submit-button'
            disabled={Object.keys(touched).length === 0 || !isValid}
          />
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  formField: state.login.username,
  codes: state.login.codes,
  currency: state.storeConfig.base_currency_code,
});

export default connect(mapStateToProps)(Register);
