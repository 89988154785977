import { gql } from '@apollo/client';

export const CHECK_COMPANY_USER_EXISTS = gql`
  query CheckCompanyUserExists($field_value: String!, $type: String) {
    checkCompanyUserExists(field_value: $field_value, type: $type) {
      status
      type
    }
  }
`;

export const LOGIN_OTP = gql`
  mutation LoginOTP($mobileNumber: String!, $websiteId: Int) {
    loginOTP(mobileNumber: $mobileNumber, websiteId: $websiteId) {
      status
      message
    }
  }
`;

export const LOGIN_OTP_VERIFY = gql`
  query LoginOTPVerify($mobileNumber: String!, $otp: String!, $websiteId: Int) {
    loginOTPVerify(mobileNumber: $mobileNumber, otp: $otp, websiteId: $websiteId) {
      message
      status
      token
    }
  }
`;

export const CREATE_ACCOUNT_OTP = gql`
  mutation CreateAccountOTP($mobileNumber: String!, $websiteId: Int) {
    createAccountOTP(mobileNumber: $mobileNumber, websiteId: $websiteId) {
      status
      message
    }
  }
`;

export const CREATE_ACCOUNT_OTP_VERIFY = gql`
  query CreateAccountOTPVerify($mobileNumber: String!, $otp: String!) {
    createAccountOTPVerify(mobileNumber: $mobileNumber, otp: $otp) {
      message
      status
    }
  }
`;

export const IS_EMAIL_AVAILABLE = gql`
  mutation IsEmailAvailable($email: String!) {
    isEmailAvailable(email: $email) {
      is_email_available
    }
  }
`;

export const VALIDATE_GST = gql`
  query ValidateGST($GST: String!) {
    validateGST(GST: $GST) {
      gst
      name
      principal_address
      address {
        street1
        street2
        city
        state
        state_code
        country
        pincode
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $company_name: String!
    $firstname: String!
    $lastname: String!
    $company_email: String!
    $mobilenumber: String!
    $gstin: String
    $fileName: String
    $base64_encoded_file: String
    $password: String!
    $street: String
    $city: String!
    $pincode: String!
    $region_code: String
    $country_id: CountryCodeEnum!
    $business_details_type: DetailsType!
    $pan_card: String
    $business_address: String!
    $entity_name: String
    $date_of_issue: String
    $pan_number: String
    $type_of_holder: String
    $customer_business_entity: String
  ) {
    createCompany(
      input: {
        company_name: $company_name
        company_email: $company_email
        company_admin: {
          email: $company_email
          firstname: $firstname
          lastname: $lastname
          mobilenumber: $mobilenumber
          name: $fileName
          base64_encoded_file: $base64_encoded_file
          password: $password
          business_details_type: $business_details_type
          pan_card: $pan_card
          business_address: $business_address
          entity_name: $entity_name
          date_of_issue: $date_of_issue
          pan_number: $pan_number
          type_of_holder: $type_of_holder
          customer_business_entity: $customer_business_entity
        }
        legal_name: $company_name
        customer_gstin: $gstin
        legal_address: {
          street: [$street]
          city: $city
          region: { region_code: $region_code }
          postcode: $pincode
          country_id: $country_id
          telephone: $mobilenumber
        }
      }
    ) {
      company {
        id
        name
        company_admin {
          email
          firstname
          lastname
          telephone
          companyUser
        }
        legal_address {
          street
          city
          region {
            region_code
            region_id
          }
          postcode
          telephone
        }
      }
    }
  }
`;

export const UPDATE_GST_DETAILS = gql`
  mutation UpdateGstDetails(
    $businessAddress: String!
    $customerGstin: String!
    $name: String!
    $base64EncodedFile: String!
    $gstinEntityName: String!
  ) {
    UpdateGstDetails(
      business_address: $businessAddress
      customer_gstin: $customerGstin
      name: $name
      base64_encoded_file: $base64EncodedFile
      gstin_entity_name: $gstinEntityName
    )
  }
`;

export const ADD_GST_DETAILS = gql`
  mutation addGstDetails(
    $gst: String!
    $name: String!
    $address: String!
    $s1: String!
    $s2: String!
    $city: String!
    $state: String!
    $pincode: String!
    $country: String!
    $stateCode: String!
    $fname: String!
    $file: String!
    $entity: String!
  ) {
    addGstdetails(
      gst: $gst
      companyname: $name
      principal_address: $address
      street1: $s1
      street2: $s2
      city: $city
      state: $state
      pincode: $pincode
      country: $country
      state_code: $stateCode
      name: $fname
      base64_encoded_file: $file
      gstin_entity_name: $entity
    ) {
      success
      message
    }
  }
`;

export const CATEGORIES = gql`
  query Categories {
    categories {
      items {
        children {
          id
          uid
          level
          name
          url_path
          url_key
          display_in_top_menu
          include_in_menu
          children {
            id
            uid
            level
            name
            url_path
            url_key
            display_in_top_menu
            include_in_menu
            image
            category_image_2
            children {
              id
              uid
              level
              name
              url_path
              url_key
              display_in_top_menu
              include_in_menu
            }
          }
        }
      }
    }
  }
`;

export const GET_BRANDS_LIST = gql`
  query GetBrandsList {
    getBrandsList {
      is_monetized
      entity_id
      url_key
      title
      is_popular
      is_exclusive
      is_justin
      is_featured
      image
    }
  }
`;

export const COMPANY = gql`
  query Company {
    customerCart {
      id
    }
    customer {
      firstname
      customer_gstin
      gstDetails {
        customer_gstin
        business_address
        gstin_certificate
        gstin_entity_name
      }
      panDetails {
        pan_number
        entity_name
        date_of_issue
        pan_card
        type_of_holder
        first_name
        middle_name
        last_name
        pan_status
      }
      customer_business_entity
      lastname
      mobilenumber
      id
      email
      gender
      dob
      emailVerified
      gstDetails {
        customer_gstin
        business_address
        gstin_entity_name
      }
    }
    company {
      company_admin {
        firstname
        customer_gstin
        emailVerified
      }
      email
      legal_address {
        street
        city
        region {
          region
        }
        postcode
        telephone
      }
      legal_name
      name
    }
  }
`;

export const COMPANY_DETAILS = gql`
  query getCompanyDetails {
    customer {
      firstname
      lastname
      customer_gstin
      gstDetails {
        customer_gstin
        business_address
      }
      gstDetails {
        customer_gstin
        business_address
        gstin_entity_name
      }
    }
    company {
      email
      legal_address {
        street
        city
        region {
          region
          region_code
        }
        postcode
        telephone
      }
    }
  }
`;

export const GST_INPUT_CREDIT = gql`
  query GstInputCredit($startDate: String!, $endDate: String!, $currentPage: Int!) {
    customer {
      gstDetails {
        customer_gstin
        business_address
      }
      customer_business_entity
      email
    }
    company {
      company_admin {
        firstname
        customer_gstin
        addresses {
          city
          region {
            region
            region_code
            region_id
          }
          country_code
          company
          postcode
          street
        }
      }
      email
      id
      legal_name
      name
      gst_balance(
        filter: { start_date: $startDate, end_date: $endDate }
        pageSize: 6
        currentPage: $currentPage
        sort: 0
      ) {
        total
        items {
          month
          gst_input_credit_date
          gst_input_credit_earned
        }
        page_info {
          current_page
          page_size
          total_pages
        }
        total_count
      }
    }
  }
`;

export const CUSTOMER_TYPE_CHECK = gql`
  query CustomerTypeCheck {
    customerTypeCheck {
      type
    }
  }
`;

export const DELETE_COMPANY_BY_USER = gql`
  mutation DeleteCompanyByUser {
    deleteCompanyByUser {
      success
      message
    }
  }
`;

export const VALIDATE_PAN = gql`
  mutation ValidatePAN($name: String!, $PAN: String!) {
    validatePAN(name: $name, PAN: $PAN) {
      pan_link
      pan_no
      pan_name
      pan_date_of_issue
      pan_type
      typeOfHolder
      firstName
      middleName
      lastName
      panStatus
    }
  }
`;

export const GET_VOLUME_DISCOUNT_DETAILS = gql`
  query GetVolumeDiscountDetails {
    getVolumeDiscountDetails {
      gb_cash_earned
      offer_end_date
      offer_name
      offer_price_limit
      offer_start_date
      total_spent
      is_cash_given
      period_discount
      destination
      milestone
      discount_on_milestone {
        target
        discount_percentage
      }
    }
  }
`;
