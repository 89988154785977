import React, { useEffect, useState } from 'react';
import { Link, createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import { CAT_DATA, FOOTER_SEO_CONTENT, STATIC_CONTENT } from 'Gql/query';
import { encodeData } from 'Utils/encodeBase64';
import { IsB2BLogged } from 'B2B/Utils/IsB2B';
import { notification } from 'Utils/Toast';
import { FooterSkeleton } from './Skeleton';
import InnerHtml from './InnerHtml';
import './Footer.css';

function FooterNavPages({ data, navigate }) {
  return data.colomns?.map((d, index) => (
    <div
      key={index}
      className={`col-sm-12 col-md-6 ${d.url_key === 'categoryList' ? 'col-lg-4' : 'col-lg-2'}`}
      style={{
        paddingRight: `${d.url_key === 'categoryList' ? '0px !important' : ''}`,
      }}
    >
      <div className={`${d.url_key === 'categoryList' ? 'popularSearch' : ''}`}>
        <ul>
          <h2 style={{ marginBottom: '30px' }}>{d.name}</h2>
          <div
            style={
              d.url_key === 'categoryList'
                ? {
                    display: 'flex',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                  }
                : {}
            }
          >
            {d?.pages?.map((i, index) => (
              <React.Fragment key={index.toString()}>
                {d.type === 'categories' ? (
                  <li>
                    <Link to={`/category/${i?.url_key}`}>{i?.name}</Link>
                  </li>
                ) : d.url_key === 'categoryList' ? (
                  <span
                    role='button'
                    tabIndex={0}
                    className='button-reset'
                    onClick={() => {
                      navigate?.({
                        pathname: `/product-listing/${i?.url_path}`,
                        search: createSearchParams?.({
                          filters: encodeData?.({
                            category_uid: {
                              in: [i?.uid],
                            },
                          }),
                          firstFilters: encodeData?.({
                            category_uid: {
                              in: [i?.uid],
                            },
                          }),
                        })?.toString(),
                      });
                      window.scrollTo?.({
                        top: 0,
                      });
                    }}
                  >
                    {i?.name}
                  </span>
                ) : (
                  <li>
                    <Link target={d.name === 'Help' && '_blank'} to={i?.url_key}>
                      {i?.name}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            ))}
          </div>
        </ul>
      </div>
    </div>
  ));
}

function FooterNavAccessibility({ footerData, navigate }) {
  return (
    <div className='secondrow'>
      <div className='row secondrowresponsive'>
        {footerData &&
          Object.keys(footerData).length > 0 &&
          footerData?.section.map(
            (data, index) =>
              data.name === 'secondrow' && (
                <FooterNavPages key={index} data={data} navigate={navigate} />
              ),
          )}
      </div>
    </div>
  );
}

function ImageCard({ props }) {
  return (
    <div className='first-row-card gap-1'>
      <div className='first-row-card-details'>
        <div className='first-row-card-details-title'>{props?.title}</div>
        <div className='first-row-card-details-subtitle'>{props?.subtitle}</div>
      </div>
      <img className='first-row-card-icons' src={props?.url_key} alt={props?.title} />
    </div>
  );
}

function CardRow({ cardsData }) {
  // getting first four entries from the list and show them as cards
  return cardsData?.length && cardsData.slice(0, 4).map((data) => <ImageCard props={data} />);
}

function SocialIcon({ data }) {
  const pages = data?.type === 'icon_social' ? data?.pages.slice(0, 5) : data?.pages;
  return (
    pages &&
    pages.map((i, index) => (
      <Link
        key={index.toString()}
        to={i?.url_key}
        className={`${data?.type === 'icon_social' ? 'icon-social' : ''}`}
        target='_blank'
      >
        <img
          style={{
            width: '70%',
            minWidth: `${data?.type === 'icon_social' ? '' : '140px'}`,
          }}
          src={i?.image_url}
          alt={i?.name}
        />
      </Link>
    ))
  );
}

function SocialLinks({ data }) {
  return data?.colomns?.map((d, index) => (
    <div
      key={index}
      className={`ft-last ${
        data?.colomns.length === 2 ? 'col-12 ft-margin' : 'col-sm-12 col-md-6 col-lg-4'
      } `}
    >
      <div className='d-flex flex-wrap'>
        <div className='text-center w-100 d-flex flex-column align-items-center'>
          <h2>{d.name}</h2>
          <div
            className={`d-flex justify-content-center gap-3 align-items-center  ${
              d.type !== 'icon_social' ? 'store-wrapper' : ''
            }`}
          >
            <SocialIcon data={d} />
          </div>
        </div>
      </div>
    </div>
  ));
}

function FooterSocialLinks({ footerData }) {
  return (
    <div className='thirdrow'>
      <div className='row thirdrowresponsive social_links'>
        {footerData &&
          footerData.section?.map(
            (data, index) => data.name === 'thirdrow' && <SocialLinks key={index} data={data} />,
          )}
      </div>
    </div>
  );
}

function Footer({ storeLoading, seoFooter }) {
  const [footerData, setFooterData] = useState({});
  const [readMore, setReadMore] = useState(false);
  const [footerContent, setFooterContent] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [activeKey, setActiveKey] = useState('0');
  const [hasScrolled, setHasScrolled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hiddenRoutes = ['/shopping-cart', '/shopping-cart/address', '/payment'];

  const isHiddenRoute = hiddenRoutes.includes(location.pathname);

  useEffect(() => {
    setActiveKey(null);
  }, [location]);

  const handleAccordionChange = (eventKey) => {
    setActiveKey(eventKey);
  };

  const [getStaticContent, { data: staticData, loading: staticLoading, error: staticError }] =
    useLazyQuery(STATIC_CONTENT);

  const [getCatData, { data: catData, loading: catLoading }] = useLazyQuery(CAT_DATA);

  const [getFooterSEOQuery, { data: cmsData, loading: cmsLoading }] =
    useLazyQuery(FOOTER_SEO_CONTENT);

  const handleReadMore = () => {
    setFooterContent(
      readMore
        ? cmsData.seoFooterContent[0].seo_footer_short_content
        : cmsData.seoFooterContent[0].seo_footer_short_content +
            cmsData.seoFooterContent[0].seo_footer_content,
    );
    setReadMore(!readMore);
  };

  useEffect(() => {
    if (!staticLoading && !staticError && staticData) {
      setFooterData(JSON.parse(staticData?.getStaticContent[0]?.value.replaceAll('\r\n', '')));
    }
  }, [staticLoading, storeLoading, staticData]);

  useEffect(() => {
    if (!cmsLoading && hasScrolled) {
      const bottomFooter = document.querySelector('.bottomFooter');

      // Adding read more button to last p tag in backend CMS
      if (bottomFooter) {
        const allParagraphs = bottomFooter.querySelectorAll('p');
        const lastP = allParagraphs[allParagraphs.length - 1];
        if (lastP) {
          const readMoreBtn = document.createElement('span');
          readMoreBtn.className = 'readMore';
          readMoreBtn.onclick = handleReadMore;
          readMoreBtn.style.cursor = 'pointer';
          readMoreBtn.innerText = readMore ? 'Read Less...' : 'Read More...';
          lastP.appendChild(readMoreBtn);
        }
      }
    }
  }, [footerContent, readMore, cmsLoading, hasScrolled, activeKey]);

  footerData.section?.map((data) => {
    if (data.name === 'secondrow') {
      data.colomns?.map((d) => {
        if (d.url_key === 'categoryList') {
          // eslint-disable-next-line no-param-reassign
          d.pages = catData?.categoryList;
        }
        return [];
      });
    }
    return [];
  });

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(true);
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchAllFooterData = async () => {
      if (hasScrolled) {
        getStaticContent({
          variables: {
            content: IsB2BLogged() ? 'footer_b2b' : 'footer_section',
          },
        });
        getCatData();

        if (Object.keys(seoFooter).length !== 0) {
          try {
            const res = await getFooterSEOQuery({
              variables: { ...seoFooter, businessType: IsB2BLogged() ? 'b2b' : 'b2c' },
            });
            setReadMore(false);
            setFooterContent(res.data.seoFooterContent[0]?.seo_footer_short_content || null);
          } catch (error) {
            notification.error(error.message);
          }
        }
      }
    };

    fetchAllFooterData();
  }, [hasScrolled, seoFooter, location.pathname]);

  return (!isMobile || !isHiddenRoute) && hasScrolled ? (
    <footer id='my-footer'>
      <div className='footer-wrapper gbGrid'>
        {staticLoading || catLoading || cmsLoading || storeLoading ? (
          <FooterSkeleton />
        ) : isMobile ? (
          <Accordion flush activeKey={activeKey} onSelect={handleAccordionChange}>
            <Accordion.Item eventKey='0'>
              <Accordion.Header className={activeKey && 'accordionExpanded footer-close-btn'}>
                {!activeKey ? (
                  <div>
                    <span>
                      GlamourBook: Your One-Stop Destination For All Things Beauty, Personal Care,
                      and Wellness!
                    </span>
                  </div>
                ) : null}
              </Accordion.Header>
              {activeKey && (
                <Accordion.Body className='p-0'>
                  <>
                    <div className='firstrow'>
                      <div className='mt-4  firstrow-wrp container-fluid'>
                        <div className='first-row-card-wrapper'>
                          {footerData &&
                            footerData.section?.map(
                              (data) =>
                                data.name === 'firstrow' && <CardRow cardsData={data.colomns} />,
                            )}
                        </div>
                      </div>
                    </div>

                    <div className='secondrow pb-0'>
                      <div className='container-fluid'>
                        <div className=''>
                          {footerData &&
                            footerData.section?.map(
                              (data) =>
                                data.name === 'secondrow' &&
                                data.colomns.map((d) => (
                                  <div className='col-12 col-sm-12 col-md-12 col-lg-3 categorylist-links'>
                                    <div
                                      className={`${
                                        d.url_key === 'categoryList' ? 'popularSearch' : ''
                                      }`}
                                    >
                                      <ul style={{ padding: '0px 6px 0px 6px' }}>
                                        <h2 style={{ marginBottom: '10px' }}>{d.name}</h2>
                                        <div
                                          style={
                                            d.url_key === 'categoryList'
                                              ? {
                                                  display: 'flex',
                                                  justifyContent: 'start',
                                                  flexWrap: 'wrap',
                                                  rowGap: '10px',
                                                }
                                              : {}
                                          }
                                        >
                                          {d?.pages?.map((i, index) => (
                                            <React.Fragment key={index.toString()}>
                                              {d.type === 'categories' ? (
                                                <li>
                                                  <Link to={`/category/${i?.url_key}`}>
                                                    {i?.name}
                                                  </Link>
                                                </li>
                                              ) : d.url_key === 'categoryList' ? (
                                                <span
                                                  role='button'
                                                  tabIndex={0}
                                                  className='button-reset'
                                                  onClick={() => {
                                                    navigate?.({
                                                      pathname: `/product-listing/${i?.url_path}`,
                                                      search: createSearchParams?.({
                                                        filters: encodeData?.({
                                                          category_uid: {
                                                            in: [i?.uid],
                                                          },
                                                        }),
                                                        firstFilters: encodeData?.({
                                                          category_uid: {
                                                            in: [i?.uid],
                                                          },
                                                        }),
                                                      })?.toString(),
                                                    });
                                                    window.scrollTo?.({
                                                      top: 0,
                                                    });
                                                  }}
                                                >
                                                  {i?.name}
                                                </span>
                                              ) : (
                                                <li>
                                                  <Link
                                                    target={d.name === 'Help' && '_blank'}
                                                    to={i?.url_key}
                                                  >
                                                    {i?.name}
                                                  </Link>
                                                </li>
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </div>
                                      </ul>
                                    </div>
                                  </div>
                                )),
                            )}
                        </div>
                      </div>
                    </div>

                    <div className='thirdrow'>
                      <div className='container-fluid'>
                        <div className='row social_links'>
                          {footerData &&
                            footerData.section?.map(
                              (data) =>
                                data.name === 'thirdrow' &&
                                data.colomns.map((d) => (
                                  <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='d-flex flex-wrap'>
                                      <div className='text-center w-100'>
                                        <h2>{d.name}</h2>
                                        <div className='d-flex justify-content-center align-items-center social-image-wrapper'>
                                          {d.pages?.map((i, index) => (
                                            <Link
                                              key={index.toString()}
                                              to={i.url_key}
                                              className={`${
                                                d.type === 'icon_social'
                                                  ? 'icon-social'
                                                  : 'commercial-links'
                                              }`}
                                              target='_blank'
                                            >
                                              <img
                                                style={{ width: '70%' }}
                                                src={i.image_url}
                                                alt={i.name}
                                              />
                                            </Link>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )),
                            )}
                        </div>
                      </div>
                    </div>

                    <div className='fourthrow'>
                      <div className='container-fluid border-bottom border-1 border-color-d9d9d9'>
                        <InnerHtml element='div' html={footerContent} className='bottomFooter' />
                      </div>
                    </div>
                  </>
                </Accordion.Body>
              )}
            </Accordion.Item>
          </Accordion>
        ) : (
          <>
            <div className='firstrow'>
              <div className='mt-4 firstrow-wrp'>
                <div className='first-row-card-wrapper'>
                  {footerData &&
                    footerData.section?.map(
                      (data) =>
                        data.name === 'firstrow' &&
                        data.colomns.map((d) => (
                          <div className='first-row-card gap-1'>
                            <img className='first-row-card-icons' src={d.url_key} alt='' />
                            <div className='first-row-card-details'>
                              <div className='first-row-card-details-title'>{d.title}</div>
                              <div className='first-row-card-details-subtitle'>{d.subtitle}</div>
                            </div>
                            <div className='vertical-line' />
                          </div>
                        )),
                    )}
                </div>
              </div>
            </div>

            <FooterNavAccessibility footerData={footerData} navigate={navigate} />
            <FooterSocialLinks footerData={footerData} />

            {footerContent && (
              <div className='fourthrow'>
                <InnerHtml element='div' html={footerContent} className='bottomFooter' />
              </div>
            )}
          </>
        )}
      </div>
    </footer>
  ) : null;
}

const mapStateToProps = (state) => ({
  storeLoading: state.storeConfigloading,
  seoFooter: state.global.seoFooter,
});
export default connect(mapStateToProps)(Footer);
