/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import OTPInput from 'Hooks/Otp';
import 'Pages/Profile/myprofile.css';
import {
  EMAIL_LOGIN_OTP_MUTATION,
  EMAIL_LOGIN_OTP_VERIFY_MUTATION,
  LOGIN_OTP_MUTATION,
  LOGIN_OTP_VERIFY,
} from 'Gql/query';
import { changeFormType, changeTrigger } from 'Features/login';
import { dispatchActionsOnFirstLoad } from 'Features/global/globalAction';
import { notification } from 'Utils/Toast';
import { setAuth } from 'Utils/setAuth';
import loginLogo from 'assets/images/GBook_Logo-B2C.svg';
import { PencilLine } from 'phosphor-react';

function VerifyOtpForgotPassword({ formvalue }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // When a user logs in on the guest cart page, they should be redirected to their exact cart page.
  const isUserOnGuestCart = () => {
    if (pathname.startsWith('/checkout/address') && localStorage.getItem('token'))
      navigate('/shopping-cart');
  };
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [err, seterr] = useState('');
  const [otp, setOtp] = useState('');

  const [time, setTime] = useState(30);
  const [timeTrue, setTimeTrue] = useState(true);
  const [EmailLoginOTPVerify] = useLazyQuery(EMAIL_LOGIN_OTP_VERIFY_MUTATION, {
    context: setAuth(),
  });
  const [LoginOTPVerify] = useLazyQuery(LOGIN_OTP_VERIFY);
  const [LoginOTP] = useMutation(LOGIN_OTP_MUTATION, {
    context: setAuth(),
  });
  const [emailLoginOTP] = useMutation(EMAIL_LOGIN_OTP_MUTATION, {
    context: setAuth(),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formvalue.type === 'email') {
        await EmailLoginOTPVerify({
          variables: { email: formvalue.field, otp, websiteId: 1 },
        }).then((verifyOtp) => {
          if (verifyOtp.data.emailLoginOTPVerify.status) {
            localStorage.setItem('token', verifyOtp.data.emailLoginOTPVerify.token);
            dispatchEvent(new Event('storage'));
            dispatch(dispatchActionsOnFirstLoad());
            dispatch(changeTrigger());
            dispatch(changeFormType('login'));
            notification.success('Yay! You logged in successfully');
          } else {
            if (verifyOtp.data.loginOTPVerify.message !== 'Verification Code Invalid') {
              notification.error(verifyOtp.data.loginOTPVerify.message);
            }
            throw new Error(verifyOtp.data.emailLoginOTPVerify.message);
          }
        });
      } else {
        await LoginOTPVerify({
          variables: { mobileNumber: formvalue.field.replace('+', ''), otp, websiteId: 1 },
        }).then((verifyOtp) => {
          if (verifyOtp.data.loginOTPVerify.status) {
            localStorage.setItem('token', verifyOtp.data.loginOTPVerify.token);
            dispatchEvent(new Event('storage'));
            dispatch(dispatchActionsOnFirstLoad());
            dispatch(changeTrigger());
            dispatch(changeFormType('login'));
            isUserOnGuestCart();
            notification.success('Yay! You logged in successfully');
          } else {
            if (verifyOtp.data.loginOTPVerify.message !== 'Verification Code Invalid') {
              notification.error(verifyOtp.data.loginOTPVerify.message);
            }
            throw new Error(verifyOtp.data.loginOTPVerify.message);
          }
        });
      }
    } catch (error) {
      seterr(error.message);
    }
  };
  const resendOTP = async () => {
    try {
      seterr('');
      if (formvalue.type !== 'email') {
        await LoginOTP({
          variables: { mobileNumber: formvalue.field.replace('+', ''), websiteId: 1 },
        }).then((SendOtp) => {
          if (SendOtp.data.loginOTP.status) {
            setTime(() => 30);
            setTimeTrue(true);
            // setloading(() => false);
            // setMessage(() => SendOtp.data.loginOTP.message);
            // dispatch(changeFormType("VerifyOtpForgotPassword"));
          } else {
            throw new Error(SendOtp.data.loginOTP.message);
          }
        });
      } else {
        await emailLoginOTP({ variables: { email: formvalue.field, websiteId: 1 } }).then(
          (SendOtp) => {
            if (SendOtp.data.emailLoginOTP.status) {
              setTime(() => 30);
            } else {
              throw new Error(SendOtp.data.emailLoginOTP.message);
            }
          },
        );
      }
    } catch (error) {
      seterr(error.message);
    }
  };
  // String Replace
  const replaceAt = function (string, start, replacement) {
    let end = string.search('@');
    if (end === -1) end = string.length - 3;
    const subString = string.substring(start, end);
    return string.replace(subString, replacement);
  };

  // validators
  const validate = (otp) => {
    if (otp) {
      if (/^[0-9]*$/.test(otp)) {
        seterr('');
        setIsSubmit(() => true);
      } else {
        seterr('Enter numbers only');
        setIsSubmit(() => false);
      }
    } else {
      setIsSubmit(() => false);
      seterr('Please enter 6 digit OTP');
    }
  };

  // const count = useRef(0);
  const onChange = (otp) => {
    setOtp(() => otp);
    validate(otp);
  };

  useEffect(() => {
    if (time === 0) {
      setTimeTrue(false);
      return;
    }
    const timeout = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timeout);
  }, [time]);

  return (
    <div className='login' data-testid='otp_verify'>
      <div className='login__form'>
        <img src={loginLogo} alt='Glamourbook' />
        <p className='subText'>
          Beauty's new playground where everyone is a main character. Discover brands from around
          the world, all in one place.
        </p>
        <div className='mobConfirmation'>
          <h6>Please enter the 6 digit code sent to {replaceAt(formvalue.field, 6, '****')}</h6>
          <PencilLine
            size={16}
            color='#787878'
            onClick={() => dispatch(changeFormType('login'))}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <form className='formInputs' onSubmit={handleSubmit}>
          <div className='formInnerWrapper'>
            <div className='otpWrapper d-flex flex-column'>
              <OTPInput
                autoFocus
                length={6}
                className='otp-input'
                inputClassName='otpInput'
                hasErrored={err}
                onChangeOTP={onChange}
              />
              {err.length !== 0 && (
                <p className='text-validation text-start' data-testid='otp_err'>
                  {err}
                </p>
              )}
            </div>
            <button
              type='button'
              style={{
                background: 'transparent',
                border: 0,
                float: 'right',
              }}
              className='resend'
              onClick={resendOTP}
              disabled={timeTrue}
            >
              {time !== 0 ? (
                <div>
                  <span className='resendCode resend-code'>00:{time <= 9 ? `0${time}` : time}</span>{' '}
                  <u>Resend Code</u>
                </div>
              ) : (
                <div className='resendCode'>
                  <u className='text-dark'>Resend Code</u>
                </div>
              )}
            </button>
          </div>
          <input
            type='submit'
            className='loginCta mb-0 whiteCta'
            id='username'
            data-testid='otp-btn'
            disabled={!isSubmit}
            value='Verify'
          />
        </form>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  formvalue: state.login.username,
});

export default connect(mapStateToProps)(VerifyOtpForgotPassword);
