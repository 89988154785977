import React from 'react';
import ReactDOM from 'react-dom/client';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GraphqlProvider from 'Gql';
import GbStore from 'app/store';
import App from 'App';
import * as Sentry from '@sentry/react';
import './index.css';
import reportWebVitals from 'reportWebVitals';

Sentry.init({
  dsn: 'https://97c9002c5aba4d9092081925e0c62b99@o4505227189288960.ingest.us.sentry.io/4505227272716288',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GraphqlProvider>
    <GbStore>
      <App />
    </GbStore>
  </GraphqlProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(<App />);

// const serviceWorker =  ServiceWorkerRegistration()
// console.log(serviceWorker.active)
